<template>
  <div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50" v-model="user.name">
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText title="E-mail" field="email" :formName="formName" :required="true" :maxLength="50"
            v-model="user.email" />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText title="Login" field="userName" :formName="formName" :required="true" :maxLength="50"
            v-model="user.userName">
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputPassword title="Senha" field="password" :formName="formName" :required="true" :maxLength="50"
            v-model="user.password">
          </InputPassword>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <RadioGroup title="Status" field="status" :formName="formName" :options="[
            { text: 'Ativo', value: 1 },
            { text: 'Inativo', value: 2 },
          ]" v-model="user.status" />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <Molded>
      <b-col sm="6">
        <Toggle title="E-mail Confirmado" :color="'#018000'" v-model="user.isConfirmedEmail" :changed="update" />
        <Toggle title="Celular Confirmado" :color="'#018000'" v-model="user.isConfirmedMobile" :changed="update" />
      </b-col>
    </Molded>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputWhatsApp from "@nixweb/nixloc-ui/src/component/forms/InputWhatsApp";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";

import User from "@/components/modules/adm/user/User.js";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "UserCreateUpdate",
  components: {
    Molded,
    InputText,
    InputWhatsApp,
    InputPassword,
    RadioGroup,
    Button,
    Toggle,
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "user",
      user: new User(),
      urlUpdate: "/api/v1/support/user/update",
      urlGetById: "/api/v1/support/user/get-by-id",
    };
  },
  created() {
    this.getById();
  },
  methods: {
    ...mapMutations("validation", ["removeFormDirty"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapActions("generic", ["putApi", "getApi"]),
    update() {
      let params = { url: this.urlUpdate, obj: this.user };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.getById();
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        if (response.success) {
          this.user = JSON.parse(response.content);
          let self = this;
          setTimeout(function () {
            self.removeLoading(["panel"]);
            self.removeFormDirty();
          }, 100);
        }
      });
    },
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.icon-success {
  color: green;
}

.icon-warning {
  color: #f6ba45;
}
</style>