<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-main">
         <UserCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import UserCreateUpdate from '../../../components/modules/adm/support/UserCreateUpdate.vue'
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UserCreateUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    UserCreateUpdate
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Suporte",
        title: "Usuário",
        formName: "userCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
